'use client'
import React, { useEffect, useRef, useState } from 'react'
import { Input } from '@/components/ui/input'
import { LocationSvg } from '@/icons-svg/HomeSvgIcons'
import { useMainFilterContext } from '@/components/pages/filter/context/MainFilterContext'
import { useOutsideClick } from '@/hooks'
import { goongApiRequest } from '@/api-data/goong'
import useDebounce from '@/hooks/useDebounce'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import clsx from 'clsx'

type LocationPopoverProps = {
  view?: string
  setAddress?: any
}

export default function LocationPopover({
  view,
  setAddress,
}: LocationPopoverProps) {
  const t = useTranslations('home.hero')
  const searchParams = useSearchParams()
  const ref = useRef(null)
  const { filterValue, setFilterValue, setFilterLatLngValue } =
    useMainFilterContext()
  const [value, setValue] = useState<string>('')
  const [suggestions, setSuggestions] = useState([])
  const debouncedValue = useDebounce(value, 500) // Debounce value with a 500ms delay
  const token = '6YMjC3eJinMK7IheZ3UIHdX2ni1zN0n9VAGSkhUM'

  const address = searchParams.get('address')

  const autoGetDetailPlace = async (place_id: string, address: string) => {
    const { payload } = await goongApiRequest.placeDetail(place_id, token)

    if (payload && payload?.result) {
      const { lat, lng } = payload?.result?.geometry?.location
      setFilterLatLngValue(lat, lng, address)
    }
  }

  const handleOutsideClick = () => {
    setSuggestions([])
  }

  useOutsideClick(ref, handleOutsideClick)

  useEffect(() => {
    try {
      setAddress && setAddress(address || '')
    } catch (error) {}
  }, [])

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedValue.length > 3 && token && suggestions.length === 0) {
        const { payload } = await goongApiRequest.autocomplete(
          debouncedValue,
          token
        )
        setSuggestions(payload.predictions)
      } else {
        setSuggestions([])
      }
    }

    fetchSuggestions()
  }, [debouncedValue]) // This effect depends on the debouncedValue

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return (
    <>
      <div>
        <div>
          <div className="relative">
            {view == 'home' ? (
              <div className="absolute top-2 start-0 flex items-center pointer-events-none font-medium	text-base-gray-500 gap-x-2">
                <LocationSvg />
                {t('ban_o_dau')}
              </div>
            ) : (
              ''
            )}
            <Input
              autoComplete={'off'}
              className={clsx(
                view == 'home'
                  ? 'w-full rounded-2xl h-auto pt-9 pb-3 pl-8 sm:pl-10 border-none font-semibold text-base text-base-gray-900 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-4 focus:border focus:border-base-brand-300 focus-visible:outline-base-brand-200'
                  : ''
              )}
              id="filter-start-location"
              placeholder={t('nhap_dia_diem')}
              value={value || searchParams.get('address') || ''}
              onChange={(evt) => {
                handleInputChange(evt)
                setValue(evt?.target?.value)
                try {
                  setAddress(evt?.target?.value)
                } catch (error) {}
                if (evt?.target?.value === '') {
                  setFilterLatLngValue(0, 0, '')
                }
              }}
            />
          </div>
        </div>
        {suggestions.length > 0 && (
          <div
            ref={ref}
            className="absolute z-30 w-auto text-popover-foreground outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
          >
            <div className="transition-all translate-y-3 bg-white rounded-2.5xl shadow-lg w-full p-4">
              <div className="flex flex-col gap-4 text-base-gray-500 font-medium">
                {suggestions &&
                  suggestions?.map((item: any, index) => (
                    <div
                      onClick={() => {
                        autoGetDetailPlace(item?.place_id, item?.description)
                        setValue(item?.description)
                        try {
                          setAddress(item?.description)
                        } catch (error) {}
                        setFilterValue({
                          ...filterValue,
                          address: item?.description,
                        })
                      }}
                      key={item?.place_id}
                      className="p-2 border-b border-base-gray-200 flex flex-row gap-x-2 cursor-pointer hover:bg-base-gray-100"
                    >
                      <LocationSvg />
                      <p>{item?.description}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
